import React, { useState, useRef,useEffect } from "react";
import LungHealthTestNav from "../LungHealthTest/LungHeathTestNav";
import SuccessPopup from "./SuccessPopup";
import axios from "axios";
import { AUTH_BASE_URL } from "../../utils/ApiConstants";
import { useNavigate } from "react-router-dom";
import crossimg from "../../assests/images/x-button.png";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
const EducatorAddDetails = () => {
  const navigate = useNavigate();
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const [showFullform, setshowFullForm] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const username = localStorage.getItem("USERNAME");
  const full_nameRef = useRef(null);
  const mobileNumberRef = useRef(null);
  const dropdownRef = useRef(null);
  const [formData, setFormData] = useState({
    mobileNumber: "",
    full_name: "",
    doctorVisit: "",
    inhalerUse: "",
  });

  const [errors, setErrors] = useState({
    mobileNumber: "",
    full_name: "",
    doctorVisit: "",
    inhalerUse: "",
  });

  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsUserDropdownOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleLogout = async () => {
    try {
      const accessToken = localStorage.getItem("ACCESS_TOKEN");
      const response = await axios.get(
        `${AUTH_BASE_URL}/api/v1/educator/logout`,
        {
          headers: {
            timestamp: Date.now().toString(),
            accesstoken: accessToken,
          },
        }
      );

      if (response.data.response.code === 200) {
        // Clear tokens from localStorage
        localStorage.clear();

        // Update logged in state

        // Redirect to login page or home page
        navigate("/educator-follow-up");

        console.log("Logout successful");
      } else {
        console.log("Logout failed: Unexpected response code");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const fetchUserData = async (phoneNumber) => {
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("ACCESS_TOKEN"); // Get token from localStorage
      const educatorEmail = localStorage.getItem("EDUCATOR_EMAIL");
      const response = await axios({
        method: "post",
        url: `${AUTH_BASE_URL}/api/v1/educator/user_data`,
        headers: {
          accesstoken: accessToken,
        },
        data: {
          phone_no: phoneNumber,
          educator_email : educatorEmail
        },
      });

      console.log(response.data);

      if (response.data?.data?.full_name) {
        const { full_name, previous_response } = response.data.data;
        setFormData(prev => ({
          ...prev,
          full_name: full_name || "",
          doctorVisit: previous_response?.answer_1?.toLowerCase() || "",
          inhalerUse: previous_response?.answer_2?.toLowerCase() || "",
        }));
        setUserId(response.data.data.secondary_user_id || null);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobileNumber") {
      const sanitizedValue = value.replace(/[^0-9]/g, "").slice(0, 10);
      setFormData((prevState) => ({
        ...prevState,
        [name]: sanitizedValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validateMobileNumber = (number) => {
    if (!number) {
      return "Mobile number is required";
    }
    if (number.length !== 10) {
      return "Mobile number must be 10 digits";
    }
    if (!/^[6-9]\d{9}$/.test(number)) {
      return "Please enter a valid Indian mobile number";
    }
    return "";
  };

  const validateForm = () => {
    const newErrors = {
      mobileNumber: validateMobileNumber(formData.mobileNumber),
      full_name: !formData.full_name ? "Patient name is required" : "",
      doctorVisit: !formData.doctorVisit
        ? "Please select whether patient visited a doctor"
        : "",
      inhalerUse: !formData.inhalerUse
        ? "Please select whether patient used an inhaler"
        : "",
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const getInputClassName = (fieldName) => {
    let className =
      "w-full border rounded-lg py-4 transition-all duration-300 outline-none pl-12 ";

    if (focusedField === fieldName) {
      className += "p-2 pl-12 ";
      if (formData[fieldName] && formData[fieldName]?.length > 0) {
        className += "border-[#72B53D] bg-[#F1F9EA] ";
      } else {
        className += "border-gray-300 ";
      }
    } else {
      className += "p-0 border-gray-300 ";
    }

    return className;
  };

  const handleInitialSubmit = async () => {
    if (formData.mobileNumber.length === 10) {
      await fetchUserData(formData.mobileNumber);
      setshowFullForm(true);
    }
  };

  const handleFinalSubmit = async () => {
    if (validateForm()) {
      try {
        const accessToken = localStorage.getItem("ACCESS_TOKEN");

        const payload = {
          phone_no: formData.mobileNumber,
          user_name: formData.full_name,
          response: {
            question_1: "Did patient visit a doctor?",
            answer_1: formData.doctorVisit,
            question_2: "Did patient already use or prescribed and inhaler?",
            answer_2: formData.inhalerUse,
            educator_id : localStorage.getItem('EDUCATOR_ID')
          },
          educator_id:localStorage.getItem('EDUCATOR_ID')
        };

        // Only add user_id to payload if it exists
        if (userId) {
          payload.patient_id = userId;
        }

        const response = await axios({
          method: "post",
          url: `${AUTH_BASE_URL}/api/v1/educator/user_response`,
          headers: {
            "Content-Type": "application/json",
            accesstoken: accessToken,
          },
          data: payload,
        });

        if (response.data.response.code === 200) {
          setIsSuccessPopupOpen(true);
          const payload={
            mobile_number :formData.mobileNumber,
            name : formData.full_name,
            Did_you_visit_the_doctor : formData.doctorVisit,
            Did_you_get_prescribed_an_inhaler : formData.inhalerUse,
            educator_email : localStorage.getItem('EDUCATOR_EMAIL'),
            educator_name : localStorage.getItem('USERNAME'),
            educator_id:localStorage.getItem('EDUCATOR_ID')
          }
          sendEventsToAnalyticsSystems("yatra_patient_followup", payload);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleModalCLose = () => {
    setIsSuccessPopupOpen(false);
    setFormData({
      mobileNumber: "",
      full_name: "",
      doctorVisit: "",
      inhalerUse: "",
    });
    setErrors({
      mobileNumber: "",
      full_name: "",
      doctorVisit: "",
      inhalerUse: "",
    });
    setFocusedField(null);
    setshowFullForm(false);
    setUserId(null);
    setIsLoading(false);

    // Clear any focused fields
    if (mobileNumberRef.current) mobileNumberRef.current.blur();
    if (full_nameRef.current) full_nameRef.current.blur();
  };

  return (
    <div className="flex flex-col mt-5 px-6">
      <LungHealthTestNav />
      <main className="flex-grow px-4 sm:px-6 lg:px-8 mt-8 sm:mt-10">
        <div className="flex justify-end items-center space-x-4">
          <div className="relative" ref={dropdownRef}>
            <button onClick={toggleUserDropdown} className="flex items-center ">
              <span className="mr-1">{username}</span>
              <svg
                width="15"
                height="8"
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 0.689453H1.82764L7.41384 6.89635L13 0.689453Z"
                  fill="#5C5F64"
                  stroke="#5C5F64"
                  strokeWidth="1.24138"
                />
              </svg>
            </button>
            {isUserDropdownOpen && (
              <div className="absolute right-0 mt-2 w-30 bg-[#F2F3F5] border rounded shadow-lg z-50">
                <button
                  className="block w-full px-5 py-1 hover:bg-gray-100 text-center text-red-500"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </main>
      {/* <div className="mt-6">
        <div>
          <label
            htmlFor="mobileNumber"
            className="block text-base font-medium text-gray-700 mb-1"
          >
            Enter Patient Mobile number
            <span className="text-red-500">*</span>
          </label>
          <div className="relative rounded-md">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-500 text-base">
              +91
            </div>
            <input
              type="tel"
              name="mobileNumber"
              id="mobileNumber"
              ref={mobileNumberRef}
              className={`${getInputClassName("mobileNumber")}bg-[#FBFBFB]`}
              placeholder={
                focusedField === "mobileNumber"
                  ? ""
                  : "Enter 10 digit mobile number"
              }
              value={formData.mobileNumber}
              onChange={handleInputChange}
              onFocus={() => setFocusedField("mobileNumber")}
              onBlur={() => setFocusedField(null)}
              maxLength={10}
            />
          </div>
        </div>
      </div> */}
       <label className="block text-base font-medium">
          Enter Patient Mobile number
          <span className="text-red-500">*</span>
        </label>
      <div className="relative rounded-md mt-2">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-500 text-base">
          +91
        </div>
        <input
          type="tel"
          name="mobileNumber"
          id="mobileNumber"
          ref={mobileNumberRef}
          className={`${getInputClassName("mobileNumber")}bg-[#FBFBFB]`}
          placeholder={
            focusedField === "mobileNumber"
              ? ""
              : "Enter 10 digit mobile number"
          }
          value={formData.mobileNumber}
          onChange={handleInputChange}
          onFocus={() => setFocusedField("mobileNumber")}
          onBlur={() => setFocusedField(null)}
          maxLength={10}
        />
        {formData.mobileNumber && (
          <button
            onClick={handleModalCLose}
            className="absolute right-3 top-1/2 transform -translate-y-1/2"
          >
            <img src={crossimg} alt="clear" className="w-4 h-4" />
          </button>
        )}
      </div>
      <div className="mt-6">
        <button
          onClick={handleInitialSubmit}
          className={`w-full py-3 px-4 rounded-full font-semibold text-white ${
            formData.mobileNumber.length === 10 && !showFullform
              ? "bg-[#72B53D]"
              : "bg-gray-300 cursor-not-allowed"
          }`}
          disabled={!showFullform && formData.mobileNumber.length !== 10}
        >
          Add Details
        </button>
      </div>
      {showFullform && (
        <div className="mt-5">
          <div>
            <div className="mb-4 relative">
              <label
                htmlFor="full_name"
                className="block text-base font-medium mb-1"
              >
                Patient Name
                <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="full_name"
                  name="full_name"
                  ref={full_nameRef}
                  placeholder={
                    focusedField === "full_name" ? "" : "Enter your full name"
                  }
                  className={`${getInputClassName("full_name")}bg-[#FBFBFB]`}
                  value={formData.full_name}
                  onChange={handleInputChange}
                  onFocus={() => setFocusedField("full_name")}
                  onBlur={() => setFocusedField(null)}
                  style={{ paddingLeft: "8px" }}
                />
              </div>
              {errors.full_name && (
                <p className="text-red-500 text-sm mt-1">{errors.full_name}</p>
              )}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Did patient visit a doctor?
              <span className="text-red-500">*</span>
            </label>
            <div className="flex space-x-4">
              {[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ].map((option) => (
                <label key={option.value} className="flex-1">
                  <div
                    className={`flex items-center border rounded-md p-4 cursor-pointer ${
                      formData.doctorVisit === option.value
                        ? "border-[#72B53D]"
                        : "border-[#EBEBEB] bg-[#FBFBFB]"
                    }`}
                  >
                    <input
                      type="radio"
                      name="doctorVisit"
                      value={option.value}
                      checked={formData.doctorVisit === option.value}
                      onChange={handleInputChange}
                      className="custom-radio form-radio h-4 w-4 text-[#72B53D] bg-white border-gray-300 focus:ring-[#72B53D]"
                    />
                    <span className="ml-2 text-base font-medium custom-radio-label">
                      {option.label}
                    </span>
                  </div>
                </label>
              ))}
            </div>
            {errors.doctorVisit && (
              <p className="text-red-500 text-sm mt-1">{errors.doctorVisit}</p>
            )}
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Did patient already use or prescribed an inhaler?
              <span className="text-red-500">*</span>
            </label>
            <div className="flex space-x-4">
              {[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ].map((option) => (
                <label key={option.value} className="flex-1">
                  <div
                    className={`flex items-center border rounded-md p-4 cursor-pointer ${
                      formData.inhalerUse === option.value
                        ? "border-[#72B53D]"
                        : "border-[#EBEBEB] bg-[#FBFBFB]"
                    }`}
                  >
                    <input
                      type="radio"
                      name="inhalerUse"
                      value={option.value}
                      checked={formData.inhalerUse === option.value}
                      onChange={handleInputChange}
                      className="custom-radio form-radio h-4 w-4 text-[#72B53D] bg-white border-gray-300 focus:ring-[#72B53D]"
                    />
                    <span className="ml-2 text-base font-medium custom-radio-label">
                      {option.label}
                    </span>
                  </div>
                </label>
              ))}
            </div>
            {errors.inhalerUse && (
              <p className="text-red-500 text-sm mt-1">{errors.inhalerUse}</p>
            )}
          </div>

          <div className="mt-6 mb-6">
            <button
              onClick={handleFinalSubmit}
              className={`w-full py-3 px-4 rounded-full font-semibold text-white ${
                formData.mobileNumber.length === 10 &&
                formData.full_name &&
                formData.doctorVisit &&
                formData.inhalerUse
                  ? "bg-[#72B53D]"
                  : "bg-gray-300 cursor-not-allowed"
              }`}
              disabled={
                formData.mobileNumber.length !== 10 ||
                !formData.full_name ||
                !formData.doctorVisit ||
                !formData.inhalerUse
              }
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <SuccessPopup isOpen={isSuccessPopupOpen} onClose={handleModalCLose} />
    </div>
  );
};

export default EducatorAddDetails;
