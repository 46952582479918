import React, { useEffect, useRef, useState } from 'react';
import LungHealthTestNav from '../LungHealthTest/LungHeathTestNav';
import axios from 'axios';
import { AUTH_BASE_URL } from '../../utils/ApiConstants';
import {useNavigate} from 'react-router-dom';
import ToastNew from '../../utils/ToastNew';
const EducatorFlowLoginScreen = () => {
  const navigate  = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    general: "",
  });
  
  const [showPassword, setShowPassword] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
useEffect(() => {
  
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  if(accessToken){
    navigate('/educator-add-details')
  }
}, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
      general: "",
    }));
  };

  const getInputClassName = (fieldName) => {
    let className = "w-full border rounded-lg py-4 transition-all duration-300 outline-none ";

    if (focusedField === fieldName) {
      className += "p-2 ";
      if (formData[fieldName] && formData[fieldName]?.length > 0) {
        className += "border-[#72B53D] bg-[#F1F9EA] ";
      } else {
        className += "border-gray-300 ";
      }
    } else {
      className += "p-0 border-gray-300 ";
    }

    return className;
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!formData.username.trim()) {
      newErrors.username = "Username is required";
      isValid = false;
    } else if (formData.username.length < 3) {
      newErrors.username = "Username must be at least 3 characters long";
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    } 

    setErrors(newErrors);
    return isValid;
  };
  const hanldeEncodedValues = (type) => {
    let encode = '';
    let tag_array = type.slice();
    for (let i = 0; i < tag_array.length; i++) {
      encode = btoa(tag_array[i]);
      tag_array[i] = encode;
      encode = '';
    }
    return tag_array;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    
    try {
      await authenticateAndLogin(formData.password);
    } catch (error) {
      console.error("Login error:", error);
      setErrors({
        ...errors,
        general: "Failed to process login. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const authenticateAndLogin = async (plainPassword) => {
    try {
      // First API call - Authentication
      const authResponse = await axios.post(
        `${AUTH_BASE_URL}/api/v1/authenticate`,
        {
          application_id: process.env.REACT_APP_APPLICATION_ID,
          fingerprint: "goldfish_arm64",
          device_type: "MOBILE",
          device_name: "google",
          os: "android",
          os_version: "14",
          browser: "",
          browser_version: "",
          application_version: "5.8",
          last_active: "2022-03-26 16:39:59",
        }
      );
  
      if (authResponse.data.response.code === 200) {
        const accessToken = authResponse.data.data.access_token;
        
        try {
          // Second API call - Educator Login with plain password
          const loginResponse = await axios.post(
            `${AUTH_BASE_URL}/api/v1/educator/login`,
            {
              employee_id: formData.username,
              password: plainPassword  
            },
            {
              headers: {
                accesstoken: accessToken,
              }
            }
          );
  
          if (loginResponse.data.response.code === 200) {
            const { access_token, refresh_token, educator_name,educator_email,educator_id } = loginResponse.data.data;
            
            localStorage.setItem('ACCESS_TOKEN', access_token);
            localStorage.setItem('REFRESH_TOKEN', refresh_token);
            localStorage.setItem('USERNAME', educator_name);
            localStorage.setItem('EDUCATOR_EMAIL',educator_email);
            localStorage.setItem('EDUCATOR_ID',educator_id);
            ToastNew.success('Login Successful');
            navigate('/educator-add-details');
          } else {
            const errorMessage = loginResponse.data.alert?.[0]?.message || "Login failed";
            setErrors({
              ...errors,
              general: errorMessage
            });
          }
        } catch (loginError) {
          console.log(loginError.response.data.response);
          
          if (loginError.response?.data) {
            const errorData = loginError.response.data.response;
            const errorMessage = errorData.alert?.[0]?.message || "Login failed";
            setErrors({
              ...errors,
              general: errorMessage
            });
          } else {
            setErrors({
              ...errors,
              general: "An error occurred during login. Please try again."
            });
          }
        }
      } else {
        const errorMessage = authResponse.data.alert?.[0]?.message || "Authentication failed";
        setErrors({
          ...errors,
          general: errorMessage
        });
      }
    } catch (authError) {
      if (authError.response?.data) {
        const errorData = authError.response.data;
        const errorMessage = errorData.alert?.[0]?.message || "Authentication failed";
        setErrors({
          ...errors,
          general: errorMessage
        });
      } else {
        setErrors({
          ...errors,
          general: "An error occurred during authentication. Please try again."
        });
      }
    }
  };

  const isButtonEnabled = formData.username.trim() && formData.password.trim();

  return (
    <div className="flex flex-col items-center justify-center mt-[10%]">
      <LungHealthTestNav />
      <div className="w-full max-w-2xl px-6">
        <div>
          <p className="text-center text-2xl font-semibold">Educator Login</p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4 mt-6">
          <div className="mb-4 relative">
            <label htmlFor="username" className="block text-base font-medium mb-1">
              Enter Username
              <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <input
                type="text"
                id="username"
                name="username"
                ref={usernameRef}
                placeholder={focusedField === "username" ? "" : "Enter your username"}
                className={`${getInputClassName("username")}bg-[#FBFBFB]`}
                value={formData.username}
                onChange={handleInputChange}
                onFocus={() => setFocusedField("username")}
                onBlur={() => setFocusedField(null)}
                style={{ paddingLeft: "8px" }}
              />
            </div>
            {errors.username && (
              <p className="text-red-500 text-sm mt-1">{errors.username}</p>
            )}
          </div>

          <div className="mb-4 relative">
            <label htmlFor="password" className="block text-base font-medium mb-1">
              Enter Password
              <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                ref={passwordRef}
                placeholder={focusedField === "password" ? "" : "Enter your password"}
                className={`${getInputClassName("password")}bg-[#FBFBFB]`}
                value={formData.password}
                onChange={handleInputChange}
                onFocus={() => setFocusedField("password")}
                onBlur={() => setFocusedField(null)}
                style={{ paddingLeft: "8px" }}
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>

         

          <div className="w-full">
            <button
              type="submit"
              disabled={!isButtonEnabled || isLoading}
              className={`flex justify-center px-4 py-2 text-white font-semibold text-xl rounded-full w-full ${
                isButtonEnabled && !isLoading
                  ? "bg-button-bg-color"
                  : "bg-gray-300 cursor-not-allowed text-[#969696]"
              }`}
            >
              <span>{isLoading ? "Logging in..." : "Login"}</span>
            </button>
          </div>
          {errors.general && (
            <p className="text-red-500 text-sm mt-1 text-center">{errors.general}</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default EducatorFlowLoginScreen;