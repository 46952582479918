import React from 'react';
import SuccessTick from '../../assests/images/SuccessTick.png'
const SuccessPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black bg-opacity-25" onClick={onClose} />
      
      {/* Modal */}
      <div className="bg-white rounded-2xl p-6 w-[300px] shadow-lg relative z-50 flex flex-col items-center text-center">
        {/* Success Icon */}
        <div className="w-12 h-12 absolute bottom-100 rounded-full top-[-25px] bg-[#72B53D] bg-opacity-10 flex items-center justify-center mb-4">
         <img src={SuccessTick}/>
        </div>

        {/* Message */}
        <h2 className="text-lg font-semibold mb-1">
          The details you have entered are saved
        </h2>

        {/* Button */}
        <button
          onClick={onClose}
          className="w-full bg-[#72B53D] text-white font-semibold rounded-full py-3 px-4 mt-6"
        >
          okay
        </button>
      </div>
    </div>
  );
};

export default SuccessPopup;