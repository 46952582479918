import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LungHeathTestNav from "./LungHeathTestNav";
import SwasaEN from "../../assests/images/swasaLatestBanner_en.png";
import SwasaHI from "../../assests/images/swasaLatestBanner_hi.png";
import swasaIcon from "../../assests/icons/swaasaicon.png";
// import { ChevronDown } from "lucide-react";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
import SeoHelmet from "../../utils/SeoHelmet";
import axios from "axios";
import { BASE_COMMON_URL } from "../../utils/ApiConstants";
const LungHealthTestHome = () => {
  const [language, setLanguage] = useState("English");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [urlRedirect, setUrlRedirect] = useState("");
  const [langCode, setLangCode] = useState("EN");
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accesstoken");

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const pathname = window.location.pathname;
  const educator = pathname.split('/').pop();
  const isfreemium = pathname.includes('freemium');
  const changeLanguage = (lang, code) => {
    localStorage.setItem("languageSelection", code);
    setLanguage(lang);
    setLangCode(code);
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectValue = searchParams.get("utm_source");
    if (redirectValue) {
      setUrlRedirect(redirectValue);
    }
  }, []);
  useEffect(() => {
    if (!localStorage.getItem("languageSelection")) {
      localStorage.setItem("languageSelection", "EN");
    } else {
      // If a language is already set, use it
      const storedLang = localStorage.getItem("languageSelection");
      setLangCode(storedLang);
      setLanguage(storedLang === "EN" ? "English" : "हिंदी");
    }
    const payload = {
      channel: "Web",
      language: langCode,
      screening_tool: "swaasa",
      utm_source:urlRedirect
    };
    sendEventsToAnalyticsSystems("lung_assessment", payload,localStorage.getItem("secondary_uuid"));
  }, [langCode]);
useEffect(() => {
  if (
    typeof window !== "undefined"
  ) {
    const origin = window.location.pathname;
    sessionStorage.setItem("pageOrigin", origin);
  }
}, []);

  const handleStartAssessment = async() => {
    const profileDetails = localStorage.getItem('profileDetails');
    const baseUrl = process.env.REACT_APP_WEBSITE_URL;
    const getUrl =sessionStorage.getItem('pageOrigin')
    const redirectUrl = `${getUrl}`;
  if (accessToken && profileDetails && JSON.parse(profileDetails)) {
    try {
      const storedLang = localStorage.getItem("languageSelection");
      const apiUrl = isfreemium 
          ? `${BASE_COMMON_URL}/api/v1/swaasa-services/swaasa-scan-details?lang=${storedLang}&platform=web&retry_url=${encodeURIComponent(redirectUrl)}`
          : `${BASE_COMMON_URL}/api/v1/swaasa-services/swaasa-scan-details?lang=${storedLang}&platform=web`;
      const carePlixResponse = await axios.get(
        apiUrl,
        {
          headers: {
            'accesstoken': accessToken,
          }
        }
      );
      const pwa_link = carePlixResponse?.data?.data?.pwa_link;
      // Check if the route includes paid-user
      const isPaidUserRoute = pathname.includes('freemium');
      if (isPaidUserRoute) {
        // Make an API call to check paid status
        const paidStatusResponse = await axios.get(
          `${BASE_COMMON_URL}/api/v1/swaasa-services/swaasa-scan-status`, 
          {
            headers: {
              'accesstoken': accessToken,
            }
          }
        );
        // If paid status is false, redirect to Razorpay subscription
        if (!paidStatusResponse?.data?.data?.free_scan_available) {
          navigate("/swaasa-payment", {
            state: {
              pwa_link: pwa_link
            }
          });
          return;
        }
      }
     
      navigate("/lung-health-test/assessment", {
        state: {
          pwa_link: pwa_link
        }
      });
    } catch (error) {
      console.error("Error fetching CarePlix details:", error);
    
      // Clear localStorage if error status is 400
      if (error.response?.status === 400) {
        localStorage.clear();
      }
      
      navigate("/login?url_redirect=swaasa");
    }
    // navigate("/lung-health-test/assessment");
  } else if (accessToken) {
    navigate("/lung-health-test/profile",{state:{linkFrom : educator}});
  } else {
    navigate(`/login?url_redirect=swaasa`,{state:{linkFrom : educator,isfreemium : isfreemium}});
  }
    const payload = {
      channel: "web",
      tap_type: "Start Lung Assessment",
      language: langCode,
    };
    sendEventsToAnalyticsSystems("lung_assessment_tap", payload,localStorage.getItem("secondary_uuid"));
  };
  const isHindiLang = localStorage.getItem("languageSelection") === "HI";

  return (
    <>
      <SeoHelmet title="Intro screen" description="Quick and easy assessment for lung health screening" />
      <div className="flex flex-col">
        <LungHeathTestNav />

        <main className="flex-grow px-4 sm:px-6 lg:px-8 mt-8 sm:mt-10">
          <div className="relative mb-2">
            <button
              onClick={toggleDropdown}
              className="flex items-center text-[#72B53D] ml-auto border-b-[#72B53D] border-b-2"
            >
              <span className="mr-1 ">{language}</span>
              <span>
                <svg
                  width="15"
                  height="8"
                  viewBox="0 0 15 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 0.689453H1.82764L7.41384 6.89635L13 0.689453Z"
                    fill="#72B53D"
                    stroke="#72B53D"
                    stroke-width="1.24138"
                  />
                </svg>
              </span>
              {/* <ChevronDown size={16} /> */}
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-24 bg-white border border-gray-200 rounded shadow-lg z-10">
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  onClick={() => changeLanguage("English", "EN")}
                >
                  English
                </button>
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  onClick={() => changeLanguage("हिंदी", "HI")}
                >
                  हिंदी
                </button>
              </div>
            )}
          </div>

          <h1 className="text-center text-2xl sm:text-3xl font-semibold text-[#72B53D] mb-4">
            {!isHindiLang ? `Lung health test` : `फेफड़ों का स्वास्थ्य परीक्षण`}
          </h1>

          <div className="max-w-md mx-auto mb-4 flex justify-center">
            <img
              src={!isHindiLang ? SwasaEN : SwasaHI}
              alt="Swasa Art"
              className="w-[60%] h-[15%] object-contain" // Added object-contain
              loading="eager"
              onError={(e) => {
                e.target.onerror = null;
                console.log("Image failed to load:", e);
              }}
            />
          </div>

          <div className="flex justify-center items-center gap-2">
            <p className="text-sm font-medium">Powered By</p>
            <img src={swasaIcon} alt="Swasa Icon" className="h-10" />
          </div>

          <ul className="font-normal text-[#424242] text-base list-disc pl-5 mb-2">
            <li className="mb-2">
              {!isHindiLang
                ? `Quick and easy assessment for lung health screening`
                : `फेफड़ों के स्वास्थ्य जांच के लिए त्वरित और आसान मूल्यांकन`}
            </li>
            <li>
              {!isHindiLang
                ? `Take proactive steps toward managing respiratory well-being.`
                : `श्वसन संबंधी स्वास्थ्य के प्रबंधन की दिशा में सक्रिय कदम उठाएं।`}
            </li>
          </ul>
        </main>

        <footer className="fixed bottom-2 mt-auto px-4 sm:px-6 lg:px-8">
          <div className="text-[10px] text-[#ADADAD] border-inherit border-[1px] px-4 py-2 rounded-md mb-3">
            {!isHindiLang ? (
              <p style={{fontFamily:'Arial Narrow'}}>
                <span className="font-bold">Disclaimer:</span> The Swaasa tool
                is brought to you by Cipla Digital Health Limited (CDHL) for
                informational and awareness purposes only. This tool has been
                approved by the Central Drugs Standard Control Organisation
                (CDSCO; National Regulatory Authority of India) and is{" "}
                <a
                  className="underline text-[#0000FF]"
                  href="https://journals.lww.com/jalh/fulltext/2024/04030/assessment_of_lung_health_status_by_analyzing.7.aspx"
                >
                  based on a study
                </a>
                . The tool is only meant for the purpose of screening for lung
                health and is not intended to diagnose any medical condition and
                should not be used as a substitute for professional medical
                advice, diagnosis, or treatment.
              </p>
            ) : (
              <p>
                <span className="font-bold"> अस्वीकरण:</span>स्वासा टूल आपको
                सिप्ला डिजिटल हेल्थ लिमिटेड (CDHL) द्वारा केवल सूचनात्मक और
                जागरूकता उद्देश्यों के लिए लाया गया है। इस टूल को केंद्रीय औषधि
                मानक नियंत्रण संगठन (CDSCO; भारतीय राष्ट्रीय नियामक प्राधिकरण)
                द्वारा अनुमोदित किया गया है और यह एक <a
                  className="underline text-[#0000FF]"
                  href="https://journals.lww.com/jalh/fulltext/2024/04030/assessment_of_lung_health_status_by_analyzing.7.aspx"
                >अध्ययन पर आधारित</a> है। यह टूल
                केवल फेफड़ों के स्वास्थ्य की जांच के उद्देश्य से है और इसका
                उद्देश्य किसी भी चिकित्सा स्थिति का निदान करना नहीं है और इसे
                पेशेवर चिकित्सा सलाह, निदान या उपचार के विकल्प के रूप में उपयोग
                नहीं किया जाना चाहिए।
              </p>
            )}
          </div>
          <button
            onClick={handleStartAssessment}
            className="flex justify-between items-center px-10 py-3 bg-[#72B53D] text-white font-semibold text-lg rounded-full w-full"
          >
            {!isHindiLang ? (
              <span>Start Lung Assessment</span>
            ) : (
              <span>फेफड़े का मूल्यांकन प्रारंभ करें</span>
            )}
            {/* <span>Start Lung Assessment</span> */}
            <span>{`>`}</span>
          </button>
        </footer>
      </div>
    </>
  );
};

export default LungHealthTestHome;